import datepicker from './options/datepicker';
import imasks from './options/imasks';
import regex from './options/regex';
import { validatePolish } from 'validate-polish';

// Define fields
const fields = [
    {
        name: 'firstname',
        validation: {
            type: imasks.type,
            options: imasks.options.name,
        }
    },{
        name: 'lastname',
        validation: {
            type: imasks.type,
            options: imasks.options.name,
        }
    },{
        name: 'email',
        validation: {
            type: regex.type,
            options: regex.options.email,
        }
    },{
		name: 'region',
		validation: {
            type: regex.type,
            options: regex.options.name,
		}
	},{
		name: 'answer',
		validation: {
            type: regex.type,
            options: regex.options.text,
		}
	},{
		name: 'answer2',
		validation: {
            type: regex.type,
            options: regex.options.text,
		}
	},
];

export default fields;