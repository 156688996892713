// $('.menu').on('click', function () {
//     $('#burger').toggleClass('menu-hidden')
// })

const navigation = document.getElementById('navigation');
const navigationToggler = navigation?.querySelector('.navbar-toggler');
const dropdown = document.getElementById('navigation-dropdown');

dropdown?.querySelectorAll('.nav-item').forEach((item) => {
    if (!item.classList.contains('dropdown')) {
        item.addEventListener('click', () => {
            toggleDropdown();
        });
    }
});

const toggleDropdown = () => {
    if (navigationToggler?.getAttribute('aria-expanded') == 'true') {
        navigationToggler?.click();
    }
};